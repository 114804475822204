<template>
	<v-container fluid>
		<v-row class="primary sticky-top">
			<v-col cols="12">
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>{{ $t('SmartNavBar.My_consignments') }}</v-toolbar-title>
				</v-toolbar>
			</v-col>
			<v-progress-linear v-if="loading" bottom indeterminate striped color="white"></v-progress-linear>
		</v-row>

		<v-row justify="center">
			<v-col cols="12" md="9" lg="6">
				<v-data-table v-if="consignmentList" :items="consignmentList" :headers="headers" hide-default-footer
					disable-pagination :disable-sort="$vuetify.breakpoint.mobile" />
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-btn v-if="this.rowIdent" :loading="loading" color="primary" @click="getConsignmentList()">
				{{ $t('Load_more') }}
			</v-btn>
		</v-row>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
export default {
	name: "Consignments",
	data() {
		return {
			consignmentList: [],
			loading: false,
			rowsToBatch: 100,
			rowNum: 0,
			rowIdent: "",
			headers: [
				{ text: this.$t("Consignments.Bonnr"), value: "magazijnbonnr" },
				{ text: this.$t("Product_code"), value: "anr" },
				{ text: this.$t("Product_description"), value: "omschrijving" },
				{ text: this.$t("Lotnumber"), value: "lotnr" },
				{ text: this.$t("Quantity"), value: "saldo" },
			],
		};
	},
	methods: {
		getConsignmentList() {
			this.loading = true;
			let params = {
				rowNum: this.rowNum,
				rowIdent: this.rowIdent,
				rowsToBatch: this.rowsToBatch,
				search: "",
			};
			return caas
				.rpc("getPortalUserConsignments", params)
				.then((response) => {
					this.loading = false;
					if (response.data.success.data) {
						this.consignmentList.push(
							...response.data.success.data.consignmentList
						);
						this.rowNum = response.data.success.rowNum;
						this.rowIdent = response.data.success.rowIdent;
					} else if (response.data.success.error) {
						throw response.data.success.error;
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.loading = false;
				})
		},
	},
	mounted() {
		this.getConsignmentList();
	},
};
</script>
